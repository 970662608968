<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <!-- <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
      
      </div> -->
      <div class="player listmain type-pks yzpks game-yzpks game-pks-hs">
        <!-- 10个 -->
        <div class="data">
          <h3>冠亚和</h3>
          <ul>
            <li v-for="item in rowsData[0].arr" :key="item.title" :class="item.selected?'selected':''" @click="setCurrentSelected(item)">
              <ol class="td_name">{{item.label}}</ol>
              <ol class="td_rate">{{item.Odds}}</ol>
              <ol class="td_cash lianTip"><input @click.stop="showOrHideCheck($event, item)" v-model="item.money" type="number">
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
      <yushe />
      <div class="roadmap">
        <table class="thlottab">
          <tbody>
            <tr>
              <td :class="qiuIndex==1?'selected':''" @click="changeQiu(1)">冠军</td>
              <td :class="qiuIndex==2?'selected':''" @click="changeQiu(2)">亚军</td>
              <td :class="qiuIndex==3?'selected':''" @click="changeQiu(3)">第三名</td>
              <td :class="qiuIndex==4?'selected':''" @click="changeQiu(4)">第四名</td>
              <td :class="qiuIndex==5?'selected':''" @click="changeQiu(5)">第五名</td>
              <td :class="qiuIndex==6?'selected':''" @click="changeQiu(6)">第六名</td>
              <td :class="qiuIndex==7?'selected':''" @click="changeQiu(7)">第七名</td>
              <td :class="qiuIndex==8?'selected':''" @click="changeQiu(8)">第八名</td>
              <td :class="qiuIndex==9?'selected':''" @click="changeQiu(9)">第九名</td>
              <td :class="qiuIndex==10?'selected':''" @click="changeQiu(10)">第十名</td>
            </tr>
          </tbody>
        </table>
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th :class="ltIndex==1?'selected':''" @click="changeLutuData(1)">单双</th>
              <th :class="ltIndex==2?'selected':''" @click="changeLutuData(2)">大小</th>
              <th :class="ltIndex==3?'selected':''" @click="changeLutuData(3)">冠亚和数</th>
              <th :class="ltIndex==4?'selected':''" @click="changeLutuData(4)">冠亚和单双</th>
              <th :class="ltIndex==5?'selected':''" @click="changeLutuData(5)">冠亚和大小</th>
              <th :class="ltIndex==6?'selected':''" @click="changeLutuData(6)">龙虎</th>
            </tr>
          </tbody>
        </table>
        <!--单双  -->
        <table colspan="0" id="lt_ds" cellspan="0" class="lttab">
          <tbody>
            <tr v-for="(item,index) in ltArr" :key="index">
              <td v-for="(ele,i) in item" :key="ele+i">
                <span v-if="!isNumber(ele)" :class="fontColor[ele]">{{ele}}</span>
                <span v-if="isNumber(ele)" :class="fontColor[ele>=11?'大':'小']">{{ele}}</span>
              </td>

            </tr>
          </tbody>
        </table>

      </div>
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
export default {
  name: "",
  props: [""],
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  mixins: [mixins],
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        console.log(resAArr,189)
        //冠亚组和
        let arr1 = this.filterData(resAArr, 1224, 1240, this.labelArr);

        this.rowsData[0].arr = arr1;
        
        this.$forceUpdate();
      },
    },
  },
  data() {
    return {
      groupnames:'gyzh',
      ltArr: [],
      tableType: 1,
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [{ title: "冠亚组合", arr: [] }],
      labelArr: [
        // "冠亚大",
        // "冠亚小",
        // "冠亚单",
        // "冠亚双",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
      ],
      lutu1: {
        arr1: [[], [], [], [], []],
        arr2: [[], [], [], [], []],
        arr3: [[], [], [], [], []],
        arr4: [[], [], [], [], []],
        arr5: [[], [], [], [], []],
        arr6: [[], [], [], [], []],
      },

      lutu2: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      },
      // 第一球录图
      lutu3: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      },
      // 第一球录图
      lutu4: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      },
      // 第一球录图
      lutu5: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      },
      lutu6: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      },
      lutu7: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      },
      lutu8: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      },
      lutu9: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      },
      lutu10: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      },
    };
  },

  created() {},
  methods: {
    getKJHis() {
      this.lutu1 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      };
      this.lutu2 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      };
      this.lutu3 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      };
      this.lutu4 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      };
      this.lutu5 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      };
      this.lutu6 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      };
      this.lutu7 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      };
      this.lutu8 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      };
      this.lutu9 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      };
      this.lutu10 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
        arr6: [[], [], [], [], []],
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 200,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            // 第一球----------
            let arr1_ds = [[]],
              arr1_dx = [[]],
              // 第二球
              arr2_ds = [[]],
              arr2_dx = [[]],
              // 第三球
              arr3_ds = [[]],
              arr3_dx = [[]],
              // 第四球
              arr4_ds = [[]],
              arr4_dx = [[]],
              // 第五球
              arr5_ds = [[]],
              arr5_dx = [[]],
              arr6_ds = [[]],
              arr6_dx = [[]],
              arr7_ds = [[]],
              arr7_dx = [[]],
              arr8_ds = [[]],
              arr8_dx = [[]],
              arr9_ds = [[]],
              arr9_dx = [[]],
              arr10_ds = [[]],
              arr10_dx = [[]],
              // ------------------
              arr_zh = [[]],
              arr_zhds = [[]],
              arr_zhdx = [[]];
            let arr11 = [[]];
            let arr12 = [[]];
            let arr13 = [[]];
            let arr14 = [[]];
            // --------------
            let list = resObj.data;
            list.reverse()
            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");
              // -第一球------------------------------------------------
              //  单双
              let q1 = element.codeArr[0];
              let ds1 = q1 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr1_ds, ds1);
              // 大小
              let dx1 = q1 >= 6 ? "大" : "小";
              this.initlutuData(arr1_dx, dx1);
              //  第二球--------------- ---------------------------------
              let q2 = element.codeArr[1];
              let ds2 = q2 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr2_ds, ds2, true);
              // 大小
              let dx2 = q2 >= 6 ? "大" : "小";
              this.initlutuData(arr2_dx, dx2);
              //  第三球--------------- ---------------------------------
              let q3 = element.codeArr[2];
              let ds3 = q3 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr3_ds, ds3);
              // 大小
              let dx3 = q3 >= 6 ? "大" : "小";
              this.initlutuData(arr3_dx, dx3);
              //  第四球--------------- ---------------------------------
              let q4 = element.codeArr[3];
              let ds4 = q4 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr4_ds, ds4);
              // 大小
              let dx4 = q4 >= 6 ? "大" : "小";
              this.initlutuData(arr4_dx, dx4);
              //  第五球--------------- ---------------------------------
              let q5 = element.codeArr[4];
              let ds5 = q5 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr5_ds, ds5);
              // 大小
              let dx5 = q5 >= 6 ? "大" : "小";
              this.initlutuData(arr5_dx, dx5);
              // 第六球--------------------------------------
              let q6 = element.codeArr[5];
              let ds6 = q6 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr6_ds, ds6);
              // 大小
              let dx6 = q6 >= 6 ? "大" : "小";
              this.initlutuData(arr6_dx, dx6);
              // 第七球--------------------------------------
              let q7 = element.codeArr[6];
              let ds7 = q7 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr7_ds, ds7);
              // 大小
              let dx7 = q7 >= 6 ? "大" : "小";
              this.initlutuData(arr7_dx, dx7);
              // 第八球--------------------------------------
              let q8 = element.codeArr[7];
              let ds8 = q8 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr8_ds, ds8);
              // 大小
              let dx8 = q8 >= 6 ? "大" : "小";
              this.initlutuData(arr8_dx, dx8);
              // 第九球--------------------------------------
              let q9 = element.codeArr[8];
              let ds9 = q9 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr9_ds, ds9);
              // 大小
              let dx9 = q9 >= 6 ? "大" : "小";
              this.initlutuData(arr9_dx, dx9);
              // 第十球--------------------------------------
              let q10 = element.codeArr[9];
              let ds10 = q10 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr10_ds, ds10);
              // 大小
              let dx10 = q10 >= 6 ? "大" : "小";
              this.initlutuData(arr10_dx, dx10);
              //  冠亚军和数

              let sum11 =
                Number(element.codeArr[0]) + Number(element.codeArr[1]);
              this.initlutuData(arr11, sum11);
              //  冠亚单双
              let gyds = sum11 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr12, gyds);
              //  冠亚大小
              let gydx = sum11 > 11 ? "大" : "小";
              this.initlutuData(arr13, gydx);
              // 龙虎
              let lh =
                element.codeArr[0] - element.codeArr[9] > 0 ? "龙" : "虎";
              this.initlutuData(arr14, lh);
            });
            // 第一球 单双 大小 总和
            this.buquanArr(arr1_ds, this.lutu1.arr1);
            this.buquanArr(arr1_dx, this.lutu1.arr2);
            // 第二球 单双 大小 总和
            this.buquanArr(arr2_ds, this.lutu2.arr1);
            this.buquanArr(arr2_dx, this.lutu2.arr2);
            // 第三球 单双 大小 总和
            this.buquanArr(arr3_ds, this.lutu3.arr1);
            this.buquanArr(arr3_dx, this.lutu3.arr2);
            // 第四球 单双 大小 总和
            this.buquanArr(arr4_ds, this.lutu4.arr1);
            this.buquanArr(arr4_dx, this.lutu4.arr2);
            // 第五球 单双 大小 总和
            this.buquanArr(arr5_ds, this.lutu5.arr1);
            this.buquanArr(arr5_dx, this.lutu5.arr2);
            // 第六球 单双 大小 总和
            this.buquanArr(arr6_ds, this.lutu6.arr1);
            this.buquanArr(arr6_dx, this.lutu6.arr2);
            // 第七球 单双 大小 总和
            this.buquanArr(arr7_ds, this.lutu7.arr1);
            this.buquanArr(arr7_dx, this.lutu7.arr2);
            // 第八球 单双 大小 总和
            this.buquanArr(arr8_ds, this.lutu8.arr1);
            this.buquanArr(arr8_dx, this.lutu8.arr2);
            // 第九球 单双 大小 总和
            this.buquanArr(arr9_ds, this.lutu9.arr1);
            this.buquanArr(arr9_dx, this.lutu9.arr2);
            // 第十球 单双 大小 总和
            this.buquanArr(arr10_ds, this.lutu10.arr1);
            this.buquanArr(arr10_dx, this.lutu10.arr2);
            // 冠亚和数---------------------------------
            this.buquanArr(arr11, this.lutu1.arr3);
            this.buquanArr(arr11, this.lutu2.arr3);
            this.buquanArr(arr11, this.lutu3.arr3);
            this.buquanArr(arr11, this.lutu4.arr3);
            this.buquanArr(arr11, this.lutu5.arr3);
            this.buquanArr(arr11, this.lutu6.arr3);
            this.buquanArr(arr11, this.lutu7.arr3);
            this.buquanArr(arr11, this.lutu8.arr3);
            this.buquanArr(arr11, this.lutu9.arr3);
            this.buquanArr(arr11, this.lutu10.arr3);
            // 冠亚和数单双---------------------------------
            this.buquanArr(arr12, this.lutu1.arr4);
            this.buquanArr(arr12, this.lutu2.arr4);
            this.buquanArr(arr12, this.lutu3.arr4);
            this.buquanArr(arr12, this.lutu4.arr4);
            this.buquanArr(arr12, this.lutu5.arr4);
            this.buquanArr(arr12, this.lutu6.arr4);
            this.buquanArr(arr12, this.lutu7.arr4);
            this.buquanArr(arr12, this.lutu8.arr4);
            this.buquanArr(arr12, this.lutu9.arr4);
            this.buquanArr(arr12, this.lutu10.arr4);
            // 冠亚和数大小---------------------------------
            this.buquanArr(arr13, this.lutu1.arr5);
            this.buquanArr(arr13, this.lutu2.arr5);
            this.buquanArr(arr13, this.lutu3.arr5);
            this.buquanArr(arr13, this.lutu4.arr5);
            this.buquanArr(arr13, this.lutu5.arr5);
            this.buquanArr(arr13, this.lutu6.arr5);
            this.buquanArr(arr13, this.lutu7.arr5);
            this.buquanArr(arr13, this.lutu8.arr5);
            this.buquanArr(arr13, this.lutu9.arr5);
            this.buquanArr(arr13, this.lutu10.arr5);
            // 龙虎---------------------------------
            this.buquanArr(arr14, this.lutu1.arr6);
            this.buquanArr(arr14, this.lutu2.arr6);
            this.buquanArr(arr14, this.lutu3.arr6);
            this.buquanArr(arr14, this.lutu4.arr6);
            this.buquanArr(arr14, this.lutu5.arr6);
            this.buquanArr(arr14, this.lutu6.arr6);
            this.buquanArr(arr14, this.lutu7.arr6);
            this.buquanArr(arr14, this.lutu8.arr6);
            this.buquanArr(arr14, this.lutu9.arr6);
            this.buquanArr(arr14, this.lutu10.arr6);

            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
            this.$forceUpdate();
          } else {
            this.ltArr = [];
          }
        } else {
          this.ltArr = [];
        }
      });
    },
    // 切换 不一个类型得 表格
    setTableType(num) {
      this.tableType = num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style  scoped>
.listmain div ul li {
  width: 25%;
}
</style>